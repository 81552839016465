export const media = new Proxy(
    {},
    {
        get(target, name) {
            return (strings, ...expressions) => (props) => {
                const query = props.theme.media[name];
                if (!query) return '';

                const styles = strings.map((str, index) => {
                    switch (typeof expressions[index]) {
                        case 'function':
                            return str + expressions[index](props);
                        case 'string':
                            return str + expressions[index];
                        default:
                            return str;
                    }
                });

                return `@media only screen and (max-width: ${query}px) {
                ${styles.join('')}
            }`;
            };
        },
    },
);
