const { media, scroll } = require('../src/utils/mixins');

module.exports = function GlobalStyles(theme) {
    return `
        html {
            font-size: 100%;
            ${media.phone`
                font-size: 85%;
            `}
            ${scroll}
        }
        body {
            line-height: 1.6;
            ${
                theme
                    ? `
                font-size: ${theme.dimensions.text.normal};
                font-family: ${theme.fonts.text};
                color: ${theme.colors.text};`
                    : ``
            }
 
        }
        h1, h2, h3, h4, h5, h6, label {
            font-weight: 700;
            line-height: 1.2;
            ${
                theme
                    ? `
                    font-family: ${theme.fonts.heading};
                    color: ${theme.colors.heading};`
                    : ``
            }
        }
        h1, h2, h3, h4, h5, h6, p, body {
            margin: 0;
        }
    `;
};
