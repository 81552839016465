import styled from 'styled-components';
import { transition } from '../utils/mixins';

export const Input = styled.input`
    ${transition(['border-color'])}
    ${({ theme, borderColor = 'text' }) => `
        border: ${theme.dimensions.border} solid ${theme.colors[borderColor]};
        background-color: ${theme.colors.background};
        border-radius: ${theme.dimensions.radius};
        font-family: ${theme.fonts.text};
        font-size: ${theme.dimensions.text.small};
        padding: ${theme.spacing.input};
        color: ${theme.colors.text};

        &:focus {
            border-color: ${theme.colors.primary};
        }
    `}
`;
