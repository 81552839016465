import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const query = graphql`
    {
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`;

export const Seo = ({
    description = '',
    lang = 'en',
    meta = [],
    children,
    title,
}) => {
    const { site } = useStaticQuery(query);
    const desc = description || site.siteMetadata.description;

    return (
        <>
            <Helmet
                titleTemplate={`%s | ${site.siteMetadata.title}`}
                htmlAttributes={{ lang }}
                title={title}
                meta={[
                    {
                        name: `description`,
                        content: desc,
                    },
                    {
                        property: `og:title`,
                        content: title,
                    },
                    {
                        property: `og:description`,
                        content: desc,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:creator`,
                        content: site.siteMetadata.author,
                    },
                    {
                        name: `twitter:title`,
                        content: title,
                    },
                    {
                        name: `twitter:description`,
                        content: desc,
                    },
                ].concat(meta)}
            />
            {children}
        </>
    );
};
