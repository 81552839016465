import React from 'react';
import { Navigation } from './Navigation';
import { Footer } from './Footer';
import { Seo } from './Seo';

export const Layout = ({ children, ...props }) => (
    <Seo {...props}>
        <Navigation />
        {children}
        <Footer />
    </Seo>
);
