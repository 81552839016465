import React, { useState, useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { position, transition, media } from '../../../utils/mixins';
import { Section, SectionContent } from '../../Section';
import { Logo as LogoComponent } from '../Logo';
import { Stack } from '../../Stack';
import { NavItem, NavItemText } from './NavItem';
import { MobileMenu } from './MobileMenu';
import sitemap from './sitemap';
import { Mixins, Paragraph } from '@cheapreats/react-ui';

const reducer = (state, action) => ({ ...state, ...action });

export const Navigation = () => {
    const [scrolled, setScrolled] = useState();
    const [pos, dispatch] = useReducer(reducer, {});
    useEffect(() => {
        let mounted = true;
        const handler = () => {
            if (scrolled !== !!window.scrollY && mounted) {
                setScrolled(!scrolled);
            }
        };
        window.addEventListener('scroll', handler, true);
        return () => {
            mounted = false;
            window.removeEventListener('scroll', handler, true);
        };
    }, [scrolled]);

    return (
        <Nav as="nav" background="background" scrolled={scrolled}>
            <Column>
                <Row>
                    <Paragraph margin="auto">
                        ⚠️ We are upgrading the site and features may be
                        disabled. Thank you for your patience. ⚠️
                    </Paragraph>
                </Row>
                <Content direction="row">
                    <Logo to="/">
                        <LogoComponent />
                    </Logo>
                    <NavItems spacing="container" as="ul">
                        {sitemap.map((item) => (
                            <NavItem
                                label={item.label}
                                items={item.items}
                                setPos={dispatch}
                                key={item.label}
                            />
                        ))}
                        <Box pos={pos} />
                    </NavItems>
                    <MobileMenu />
                </Content>
            </Column>
        </Nav>
    );
};

const Row = styled.div`
    ${Mixins.flex('row')}
    width: 100%;
    background-color: yellow;
`;
const Column = styled.div`
    ${Mixins.flex('column')}
    width: 100%;
`;

const Nav = styled(Section)`
    ${position('sticky', '0 auto auto', 0, 0, 'auto')}
    ${transition(['transform', 'box-shadow'])}
    transform: translateY(-${({ scrolled }) => (scrolled ? '1.25rem' : 0)});
    box-shadow: ${({ scrolled, theme }) => (scrolled ? theme.shadow : 0)};
    z-index: 100;
`;

const Content = styled(SectionContent)`
    padding-bottom: ${({ theme }) => theme.spacing.container};
    ${({ theme }) => `
        max-width: ${theme.dimensions.page.maxWidth};
    `}
    margin: auto;
`;

const Logo = styled(Link)`
    align-items: center;
    text-decoration: none;
    display: flex;
`;

const NavItems = styled(Stack)`
    margin: 0;
    margin-left: auto;
    list-style-type: none;
    position: relative;
    padding: 0;

    &:hover ${NavItemText} {
        opacity: 0.55;
    }

    ${media.tablet`
        display: none;
    `}
`;

const Box = styled.li`
    ${transition(['transform', 'height', 'opacity'])}
    ${position('absolute', 'auto', '100%', '100%', 'auto', 'auto')}
    box-sizing: border-box;
    pointer-events: none;
    z-index: 101;

    ${({ pos }) => `
        transform: translateX(${pos.x}px);
        height: ${pos.height}px;
        opacity: ${pos.show ? 1 : 0};
    `}

    ${({ theme }) => `
        background-color: ${theme.colors.background};
        border-radius: ${theme.dimensions.radius};
        width: ${theme.dimensions.nav.menuWidth};
        padding: ${theme.spacing.component};
        box-shadow: ${theme.shadow};
    `}
`;
