import { useStaticQuery, graphql } from 'gatsby';

export const useFeatureIcons = () => {
    const data = useStaticQuery(graphql`
        query {
            images: allFile(
                filter: { relativeDirectory: { eq: "featuresicons" } }
                sort: { order: ASC, fields: name }
            ) {
                nodes {
                    id
                    name
                    childImageSharp {
                        fluid(maxHeight: 800, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `);
    return data;
};
