import styled from 'styled-components';

export const Text = styled.p.attrs((props) => {
    switch (props.as) {
        case 'emoji':
            return {
                ...props,
                as: 'span',
                role: 'img',
            };
        default:
            return props;
    }
})`
    ${({ theme, size }) =>
        size ? `font-size: ${theme.dimensions.text[size] || size};` : ''}
    ${({ theme, color }) =>
        color ? `color: ${theme.colors[color] || color};` : ''}
    ${({ bold }) => (bold ? `font-weight: bold;` : '')}
    ${({ align }) => (align ? `text-align: ${align};` : '')}
`;
