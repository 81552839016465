import React from 'react';
import styled, { useTheme } from 'styled-components';
import { media } from '../../../utils/mixins';
import { Stack } from '../../Stack';
import { SmallText as S } from '@cheapreats/react-ui';
export const FooterList = ({ label, children }) => {
    return (
        <Container direction="column">
            <SmallText color="black" size="default" margin="auto auto 10px 0">
                {label}
            </SmallText>
            <List>{children}</List>
        </Container>
    );
};

const Container = styled(Stack)`
    ${media.tablet`
        margin-bottom: ${({ theme }) => theme.spacing.component} !important;
    `}
`;

const SmallText = styled(S)`
    margin-top: ${({ theme }) => theme.spacing.component};
`;

const List = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    ${({ theme }) => `
        font-size: ${theme.dimensions.text.small};
    `}
`;
