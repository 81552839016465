import React from 'react';
import Img from 'gatsby-image';
import {
    TextBox,
    PhoneBox,
    IconBox,
    Content,
    NavBox,
    OuterContainer,
    PhoneCard,
} from './Containers.js';
import { usePhoneImages } from '../../queries/usePhoneImages.js';
import { useFeatureIcons } from '../../queries/useFeatureIcons.js';
import { ROBOT_DELIVERY, STAR, ROBOT, LOCATION, GIFTBOX } from './Constants';
import theme from '../../../theme/theme';

const RobotDelivery = () => {
    const phoneQuery = usePhoneImages().images.nodes;
    const iconQuery = useFeatureIcons().images.nodes;
    return (
        <OuterContainer>
            <NavBox>
                <IconBox width="35px">
                    <Img
                        fluid={iconQuery[STAR].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
                <IconBox width="35px">
                    <Img fluid={iconQuery[ROBOT].childImageSharp.fluid} />
                </IconBox>
                <IconBox width="27px">
                    <Img
                        fluid={iconQuery[LOCATION].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
                <IconBox width="35px">
                    <Img
                        fluid={iconQuery[GIFTBOX].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
            </NavBox>
            <Content>
                <TextBox>
                    <IconBox width="35px">
                        <Img
                            fluid={
                                iconQuery[ROBOT_DELIVERY].childImageSharp.fluid
                            }
                        />
                    </IconBox>

                    <h1>Robotic Food Delivery</h1>
                    <h3>First Ever in Canada</h3>
                    <p>
                        CheaprEats has partnered with Tiny Mile to bring forth
                        the first-ever robotic food delivery service in downtown
                        Toronto.
                    </p>
                    <h3>How it works?</h3>
                    <ul>
                        <li>Place an order on the CheaprEats app.</li>
                        <li>Select the robotic food delivery option.</li>
                        <li>Tiny Mile will be notified of your order.</li>
                        <li>Robots will be sent to pick up your order.</li>
                        <li>
                            They will then safely deliver it to your address.
                        </li>
                    </ul>
                    <h3>Safety First</h3>
                    <ul>
                        <li>Contactless Delivery.</li>
                        <li>Robots sanitized after every order.</li>
                        <li>
                            Robots equipped with built-in cameras and sensors.
                        </li>
                    </ul>
                </TextBox>
                <PhoneCard backgroundColor={theme.colors.primary}>
                    <PhoneBox padding="16px">
                        <Img
                            fluid={
                                phoneQuery[ROBOT_DELIVERY].childImageSharp.fluid
                            }
                            style={{ marginTop: '50px' }}
                        />
                    </PhoneBox>
                </PhoneCard>
            </Content>
        </OuterContainer>
    );
};

export default RobotDelivery;
