export const STAR = 5;
export const ROBOT = 0;
export const LOCATION = 3;
export const GIFTBOX = 6;

export const FAVOURITE_RESTAURANTS = 0;
export const ROBOT_DELIVERY = 1;
export const INTERACTIVE_MAP = 2;
export const REWARDS_PROGRAMS = 3;

export const DOLLAR_TYPE = 1;
export const ORDER_TYPE = 4;
export const ITEM_TYPE = 2;
