// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-client-jsx": () => import("./../../../src/pages/client.jsx" /* webpackChunkName: "component---src-pages-client-jsx" */),
  "component---src-pages-design-guidelines-mdx": () => import("./../../../src/pages/designGuidelines.mdx" /* webpackChunkName: "component---src-pages-design-guidelines-mdx" */),
  "component---src-pages-developer-jsx": () => import("./../../../src/pages/developer.jsx" /* webpackChunkName: "component---src-pages-developer-jsx" */),
  "component---src-pages-ethics-jsx": () => import("./../../../src/pages/ethics.jsx" /* webpackChunkName: "component---src-pages-ethics-jsx" */),
  "component---src-pages-features-jsx": () => import("./../../../src/pages/features.jsx" /* webpackChunkName: "component---src-pages-features-jsx" */),
  "component---src-pages-giftcard-jsx": () => import("./../../../src/pages/giftcard.jsx" /* webpackChunkName: "component---src-pages-giftcard-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-loyalty-program-jsx": () => import("./../../../src/pages/loyaltyProgram.jsx" /* webpackChunkName: "component---src-pages-loyalty-program-jsx" */),
  "component---src-pages-mentorship-mdx": () => import("./../../../src/pages/mentorship.mdx" /* webpackChunkName: "component---src-pages-mentorship-mdx" */),
  "component---src-pages-news-letter-jsx": () => import("./../../../src/pages/newsLetter.jsx" /* webpackChunkName: "component---src-pages-news-letter-jsx" */),
  "component---src-pages-point-of-sale-mdx": () => import("./../../../src/pages/pointOfSale.mdx" /* webpackChunkName: "component---src-pages-point-of-sale-mdx" */),
  "component---src-pages-press-mdx": () => import("./../../../src/pages/press.mdx" /* webpackChunkName: "component---src-pages-press-mdx" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-stack-mdx": () => import("./../../../src/pages/stack.mdx" /* webpackChunkName: "component---src-pages-stack-mdx" */),
  "component---src-pages-stay-in-touch-jsx": () => import("./../../../src/pages/stayInTouch.jsx" /* webpackChunkName: "component---src-pages-stay-in-touch-jsx" */),
  "component---src-pages-students-ambassador-jsx": () => import("./../../../src/pages/studentsAmbassador.jsx" /* webpackChunkName: "component---src-pages-students-ambassador-jsx" */),
  "component---src-pages-subpages-mdx": () => import("./../../../src/pages/subpages.mdx" /* webpackChunkName: "component---src-pages-subpages-mdx" */),
  "component---src-pages-support-mdx": () => import("./../../../src/pages/support.mdx" /* webpackChunkName: "component---src-pages-support-mdx" */)
}

