import React, { useState, useRef } from 'react';
import { Link as _Link } from 'gatsby';
import { FaExternalLinkAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { position, transition } from '../../../utils/mixins';
import { Paragraph } from '@cheapreats/react-ui';

export const NavItem = ({ label, items, setPos }) => {
    const [show, setShow] = useState();
    const parentRef = useRef();
    const menuRef = useRef();
    const end = items.length - 1;

    const hide = () => {
        setPos({ show: false });
        setShow(false);
    };
    const unhide = () => {
        const { offsetLeft, offsetWidth } = parentRef.current;
        setPos({
            height: menuRef.current.offsetHeight,
            x: offsetLeft + offsetWidth,
            show: true,
        });
        setShow(true);
    };

    return (
        <Container ref={parentRef} onMouseLeave={hide} onMouseEnter={unhide}>
            <NavItemText size="default">{label}</NavItemText>
            <Menu ref={menuRef} show={show}>
                {items.map(({ label, ...props }, index) => (
                    <li key={label}>
                        <Link
                            onBlur={end === index ? hide : undefined}
                            as={props.href ? 'a' : _Link}
                            onFocus={unhide}
                            onClick={hide}
                            {...props}
                        >
                            {label}
                            {!!props.href && <Icon as={FaExternalLinkAlt} />}
                        </Link>
                    </li>
                ))}
            </Menu>
        </Container>
    );
};

const Container = styled.li`
    position: relative;
`;

export const NavItemText = styled(Paragraph)`
    ${transition(['opacity'])}
    cursor: default;
    ${({ theme }) => `
        padding: ${theme.spacing.element};
        padding-bottom: ${theme.spacing.component};
        margin-top: ${theme.spacing.element};
    `}
    ul ${Container}:hover & {
        opacity: 1;
    }
`;

const Menu = styled.ul`
    ${position('absolute', 'auto', 'auto', '0', 'auto', 'auto')}
    ${transition(['opacity'])}
    ${({ show }) =>
        show
            ? `
        opacity: 1;
    `
            : `
        pointer-events: none;
        opacity: 0;
    `}
    box-sizing: border-box;
    list-style-type: none;
    z-index: 102;

    ${({ theme }) => `
        font-size: ${theme.dimensions.text.smaller};
        width: ${theme.dimensions.nav.menuWidth};
        padding: ${theme.spacing.component};
    `}
`;

const Icon = styled.svg`
    margin-left: auto;
    ${({ theme }) => `
        height: ${theme.dimensions.text.small};
        width: ${theme.dimensions.text.small};
    `}
`;

const Link = styled.a`
    ${transition(['color'])}
    text-decoration: none;
    align-items: center;
    display: flex;
    ${({ theme }) => `
        padding: ${theme.dimensions.nav.spacing};
        color: ${theme.colors.text};
        &:hover {
            color: ${theme.colors.primary};
        }
    `}
`;
