import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
    TextBox,
    PhoneBox,
    IconBox,
    Content,
    NavBox,
    OuterContainer,
    PhoneCard,
} from './Containers.js';
import { usePhoneImages } from '../../queries/usePhoneImages.js';
import { useFeatureIcons } from '../../queries/useFeatureIcons.js';
import {
    REWARDS_PROGRAMS,
    STAR,
    ROBOT,
    LOCATION,
    GIFTBOX,
    DOLLAR_TYPE,
    ORDER_TYPE,
    ITEM_TYPE,
} from './Constants';
import theme from '../../../theme/theme';

const HeaderWithIcon = styled.div`
    display: flex;
    flex-direction: row;
    color: ${theme.colors.primary};
`;

const TypeIconBox = styled.div`
    ${({ width }) => (width ? `width: ${width};` : '')}
    margin-left: 5px;
    margin-top: 5px;
`;

const FeaturesPage = () => {
    const phoneQuery = usePhoneImages().images.nodes;
    const iconQuery = useFeatureIcons().images.nodes;
    return (
        <OuterContainer style={{ marginBottom: '0px' }}>
            <NavBox>
                <IconBox width="35px">
                    <Img
                        fluid={iconQuery[STAR].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
                <IconBox width="35px">
                    <Img
                        fluid={iconQuery[ROBOT].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
                <IconBox width="27px">
                    <Img
                        fluid={iconQuery[LOCATION].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
                <IconBox width="35px">
                    <Img fluid={iconQuery[GIFTBOX].childImageSharp.fluid} />
                </IconBox>
            </NavBox>
            <Content>
                <TextBox>
                    <IconBox width="35px" marginTop="6px">
                        <Img
                            fluid={
                                iconQuery[REWARDS_PROGRAMS].childImageSharp
                                    .fluid
                            }
                        />
                    </IconBox>

                    <h1>Loyalty Programs</h1>
                    <h3>Just your type:</h3>
                    <p>
                        Be the first in Canada to experience a whole new world
                        of loyalty programs. Select one out of the 3 different
                        options that suit your unique needs and lifestyle the
                        most!
                    </p>
                    <HeaderWithIcon>
                        <h3>Dollar Type</h3>
                        <TypeIconBox width="25px">
                            <Img
                                fluid={
                                    iconQuery[DOLLAR_TYPE].childImageSharp.fluid
                                }
                            />
                        </TypeIconBox>
                    </HeaderWithIcon>
                    <p>
                        Loyalty points and shareable points will be awarded for
                        every whole dollar that is spent.
                    </p>
                    <HeaderWithIcon>
                        <h3>Order Type</h3>
                        <TypeIconBox width="23px">
                            <Img
                                fluid={
                                    iconQuery[ORDER_TYPE].childImageSharp.fluid
                                }
                            />
                        </TypeIconBox>
                    </HeaderWithIcon>
                    <p>
                        Loyalty points and shareable points will be awarded for
                        every order that is placed, regardless of price.
                    </p>
                    <HeaderWithIcon>
                        <h3>Item Type</h3>
                        <TypeIconBox width="40px">
                            <Img
                                fluid={
                                    iconQuery[ITEM_TYPE].childImageSharp.fluid
                                }
                            />
                        </TypeIconBox>
                    </HeaderWithIcon>
                    <p>
                        Loyalty points and shareable points will be awarded for
                        every item that is ordered.
                    </p>
                </TextBox>
                <PhoneCard backgroundColor="#f57a9b">
                    <PhoneBox padding="16px">
                        <Img
                            fluid={
                                phoneQuery[REWARDS_PROGRAMS].childImageSharp
                                    .fluid
                            }
                        />
                    </PhoneBox>
                </PhoneCard>
            </Content>
        </OuterContainer>
    );
};

export default FeaturesPage;
