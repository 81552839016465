export const scroll = `&::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #efefef;

    &-thumb {
        background-color: rgba(0,0,0,0.3);
        border-radius: 999px;
    }
}`;
