import styled from 'styled-components';
import { transition, media, transform } from '../utils/mixins';

export const ImageContainer = styled.div`
    ${media.stayInTouchWrap`
        flex-basis: 100%;
    `}
    margin: 5%;
    flex-basis: 50%;
    ${transition(['transform'])}
    ${transform(0.95)}
`;
