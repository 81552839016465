import styled from 'styled-components';

const md = (dir = '', inverse) =>
    inverse
        ? `margin-${dir.startsWith('column') ? 'right' : 'bottom'}`
        : `margin-${dir.startsWith('column') ? 'bottom' : 'right'}`;

export const Stack = styled.div`
    ${({ direction }) => (direction ? `flex-direction: ${direction};` : '')}
    ${({ justify }) => (justify ? `justify-content: ${justify};` : '')}
    ${({ align }) => (align ? `align-items: ${align};` : '')}
    ${({ wrap }) => (wrap ? `flex-wrap: ${wrap};` : '')}
    ${({ theme, wrap, direction, spacing = 'component' }) => `
        & > * {
            ${md(direction)}: ${theme.spacing[spacing] || spacing};
            ${
                wrap
                    ? `${md(direction, true)}: ${
                          theme.spacing[spacing] || spacing
                      };`
                    : ''
            }
            &:last-child {
                ${md(direction)}: 0;
            }
        }
    `}
    display: flex;
`;
