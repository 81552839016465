import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link as _Link } from 'gatsby';
import { FaExternalLinkAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { Stack } from '../../Stack';
import { media, transition, position } from '../../../utils/mixins';
import sitemap from './sitemap';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Paragraph, Hooks } from '@cheapreats/react-ui';

export const MobileMenu = () => {
    const isMounted = Hooks.useMounted();
    const [show, setShow] = useState(false);
    const containerRef = useRef();

    const close = useCallback((e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setShow(false);
        }
    }, []);

    useEffect(() => {
        if (show && isMounted.current) {
            window.addEventListener('click', close);
        }
        return () => window.removeEventListener('click', close);
    }, [show, isMounted.current, close]);

    return (
        <>
            <Container
                as="ul"
                direction="column"
                show={show}
                ref={containerRef}
            >
                <CloseButton onClick={() => setShow(false)} aria-label="Close">
                    <Times />
                </CloseButton>
                {sitemap.map(({ label, items }) => (
                    <div key={label}>
                        <li>
                            <Paragraph size="h6" color="heading">
                                {label}
                            </Paragraph>
                        </li>
                        <CategoryItems>
                            {items.map(({ label, ...props }) => (
                                <li key={label}>
                                    <Link
                                        {...props}
                                        as={props.href ? 'a' : _Link}
                                    >
                                        {label}
                                        {!!props.href && (
                                            <Icon as={FaExternalLinkAlt} />
                                        )}
                                    </Link>
                                </li>
                            ))}
                        </CategoryItems>
                    </div>
                ))}
            </Container>

            <BarsButton onClick={() => setShow(!show)}>
                <MobileBars />
            </BarsButton>
        </>
    );
};

const Container = styled(Stack)`
    display: none;
    list-style-type: none;
    max-height: 80vh;
    overflow-y: auto;
    ${transition(['opacity'])}
    ${({ theme, show }) => `
        ${!show ? 'pointer-events: none;' : ''}
        opacity: ${show ? 1 : 0};
        transform: translate(${theme.spacing.component}, -${
        theme.spacing.element
    });
        background-color: ${theme.colors.background};
        border-radius: ${theme.dimensions.radius};
        padding: ${theme.spacing.component};
        box-shadow: ${theme.shadow};
        ${position(
            'absolute',
            0,
            'auto',
            theme.spacing.section,
            'auto',
            'auto',
        )}
        & > div > li > p {
            padding: ${theme.dimensions.nav.spacing}
        }
    `}

    ${media.tablet`
    display: initial;
    `}
`;

const CloseButton = styled.button`
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    ${({ theme }) => `
    ${position('absolute', 0, 'auto', theme.spacing.component, 'auto', 'auto')}
    color: ${theme.colors.text};
    &:hover {
        color: ${theme.colors.black};
    }
    `}
`;

const Times = styled(FaTimes)`
    ${({ theme }) => `
    height: ${theme.dimensions.text.normal};
    width: ${theme.dimensions.text.normal};
    `}
`;

const CategoryItems = styled.ul`
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    ${media.phone`
    grid-template-columns: 1fr;
    `}
`;

const Link = styled.a`
    ${transition(['color'])}
    text-decoration: none;
    ${({ theme }) => `
        font-size: ${theme.dimensions.text.smaller};
        padding: ${theme.dimensions.nav.spacing};
        color: ${theme.colors.text};
        &:hover {
            color: ${theme.colors.primary};
        }
        ${media.phone`
        font-size: ${theme.dimensions.text.small};
        `}
    `}
`;

const Icon = styled.svg`
    ${({ theme }) => `
    margin-left: ${theme.spacing.component};
        height: ${theme.dimensions.text.small};
        width: ${theme.dimensions.text.small};
    `}
`;

const BarsButton = styled.button`
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    margin-left: auto;
    align-self: center;
    font-size: inherit;
    ${({ theme }) => `
        color: ${theme.colors.text};
    `}
    &:hover {
        color: #000000;
    }
`;

const MobileBars = styled(FaBars)`
    display: none;
    ${media.tablet`
        display: initial;
    `}
`;
