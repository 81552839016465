import styled from 'styled-components';

export const Card = styled.div`
    box-sizing: border-box;
    ${({ theme, background = 'background', padding = 'container' }) => `
        border-radius: ${theme.dimensions.radius};
        background-color: ${theme.colors[background] || background};
        padding: ${theme.spacing[padding]};
    `}
`;
