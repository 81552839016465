import React from 'react';
import Img from 'gatsby-image';
import {
    TextBox,
    PhoneBox,
    IconBox,
    Content,
    NavBox,
    OuterContainer,
    PhoneCard,
} from './Containers.js';
import { usePhoneImages } from '../../queries/usePhoneImages.js';
import { useFeatureIcons } from '../../queries/useFeatureIcons.js';
import { INTERACTIVE_MAP, STAR, ROBOT, LOCATION, GIFTBOX } from './Constants';

const InteractiveMap = () => {
    const phoneQuery = usePhoneImages().images.nodes;
    const iconQuery = useFeatureIcons().images.nodes;
    return (
        <OuterContainer>
            <NavBox>
                <IconBox width="35px">
                    <Img
                        fluid={iconQuery[STAR].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
                <IconBox width="35px">
                    <Img
                        fluid={iconQuery[ROBOT].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
                <IconBox width="27px">
                    <Img fluid={iconQuery[LOCATION].childImageSharp.fluid} />
                </IconBox>
                <IconBox width="35px">
                    <Img
                        fluid={iconQuery[GIFTBOX].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
            </NavBox>
            <Content>
                <TextBox>
                    <IconBox width="27px">
                        <Img
                            fluid={
                                iconQuery[INTERACTIVE_MAP].childImageSharp.fluid
                            }
                        />
                    </IconBox>

                    <h1>Interactive Map</h1>
                    <h3>Simple and Convenient:</h3>
                    <p>
                        Never get lost when you’re going to pick up an order
                        from any vender, with our built-in interactive map!
                    </p>
                    <h3>One Step Ahead</h3>
                    <ul>
                        <li>Don’t need to leave the app for this map.</li>
                        <li>
                            The time it takes to reach the vendor is displayed.
                        </li>
                        <li>
                            The route is highlighted with step-by-step
                            directions.
                        </li>
                        <li>
                            If you get off track, immediately reroutes for you.
                        </li>
                        <li>
                            Makes picking up your dish and skip lines faster.
                        </li>
                    </ul>
                </TextBox>
                <PhoneCard backgroundColor="#f2c600">
                    <PhoneBox padding="16px">
                        <Img
                            fluid={
                                phoneQuery[INTERACTIVE_MAP].childImageSharp
                                    .fluid
                            }
                        />
                    </PhoneBox>
                </PhoneCard>
            </Content>
        </OuterContainer>
    );
};

export default InteractiveMap;
