import React from 'react';
import styled from 'styled-components';
import { Paragraph, Heading } from '@cheapreats/react-ui';

export const SectionTextContent = ({
    headingText,
    headingColor = 'primary',
    headingProps,
    subHeadingText,
    subHeadingColor = 'primary',
    subHeadingProps,
    paragraphText,
    paragraphColor = 'black',
    paragraphProps,
    lineHeight = '1.6',
}) => (
    <Content>
        <Heading type="h1" color={headingColor} {...headingProps}>
            {headingText}
        </Heading>
        <Heading type="h4" color={subHeadingColor} {...subHeadingProps}>
            {subHeadingText}
        </Heading>
        {paragraphText && (
            <Paragraph
                size="default"
                lineHeight={lineHeight}
                color={paragraphColor}
                margin="20px auto"
                {...paragraphProps}
            >
                {paragraphText}
            </Paragraph>
        )}
    </Content>
);

const Content = styled.div`
    width: 100%;
`;
