import React from 'react';
import styled from 'styled-components';
import { Mixins } from '@cheapreats/react-ui';

export const SectionContainer = ({
    textContent,
    imageContent,
    header,
    footer,
    reverse = false,
    margin = 'auto',
    mediaFlexDirection,
    mediaMixin = 'phone',
    backgroundColor = 'background',
    id,
    children,
}) => {
    return (
        <Background backgroundColor={backgroundColor}>
            <Container margin={margin}>
                {header}
                {(textContent || imageContent) && (
                    <ContentContainer
                        reverse={reverse}
                        id={id}
                        mediaFlexDirection={mediaFlexDirection}
                        mediaMixin={mediaMixin}
                    >
                        {textContent && (
                            <TextContentContainer>
                                {textContent}
                            </TextContentContainer>
                        )}
                        {imageContent && (
                            <ImageContentContainer>
                                {imageContent}
                            </ImageContentContainer>
                        )}
                    </ContentContainer>
                )}
                {children}
                {footer}
            </Container>
        </Background>
    );
};

const ContentContainer = styled.div`
    position: relative;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    justify-content: center;
    align-items: center;
    ${({ reverse, mediaFlexDirection, mediaMixin }) => {
        let flexDirection;
        if (mediaFlexDirection) {
            flexDirection = mediaFlexDirection;
        } else {
            flexDirection = reverse ? `column-reverse` : 'column';
        }
        return Mixins.media(mediaMixin, `flex-direction: ${flexDirection}`);
    }}
    width: 100%;
    height: 100%;
`;
const TextContentContainer = styled.div`
    ${Mixins.flex('column', 'flex-start')}
    width: 50%;
    ${Mixins.media('tablet', 'width: 100%; margin: 10px auto;')}
`;
const ImageContentContainer = styled.div`
    width: 50%;
    ${Mixins.media('tablet', 'width: 100%')}
`;
const Container = styled.div`
    position: relative;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;
    ${Mixins.flex('column')}
    ${({ theme, margin }) => `
        padding: ${theme.spacing.section};
        max-width: ${theme.dimensions.page.maxWidth};
        margin: ${margin};
    `}
`;
const Background = styled.div`
    ${({ theme, backgroundColor }) => `
        background-color: ${theme.colors[backgroundColor] || backgroundColor};
        padding: ${theme.spacing.section} 0;
    `}
`;
