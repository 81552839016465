import React from 'react';
import styled, { withTheme } from 'styled-components';
import { transition, darken } from '../utils/mixins';
import { Stack } from './Stack';

export const Button = withTheme(
    ({ rightIcon, leftIcon, children, theme, ...props }) => {
        return (
            <ButtonStyle
                spacing={theme.dimensions.button.spacing}
                as="button"
                {...props}
            >
                {leftIcon && <IconStyle as={leftIcon} />}
                {children && <span>{children}</span>}
                {rightIcon && <IconStyle as={rightIcon} />}
            </ButtonStyle>
        );
    },
);

export const ButtonStyle = styled(Stack)`
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;

    ${transition(['background-color', 'border-color', 'color'])}
    ${({ theme }) => `
        padding: ${theme.spacing.input};
        border-radius: ${theme.dimensions.radius};
        font-family: ${theme.fonts.heading};
        font-size: ${theme.dimensions.text.button};
    `}
    ${({ theme, variant, background = 'background', color = 'text' }) => {
        const b = theme.colors[background] || background;
        const c = theme.colors[color] || color;
        const s = variant === 'solid';
        const bg = s ? c : b;
        return `
            border: ${c} solid ${theme.dimensions.border};
            background-color: ${bg};
            color: ${s ? b : c};

            &:hover {
                ${s ? `border-color: ${darken(c)};` : ''}
                background-color: ${darken(bg)};
            }

            &:active {
                ${s ? `border-color: ${darken(c, 2)};` : ''}
                background-color: ${darken(bg, 2)};
            }
        `;
    }}
`;

const IconStyle = styled.svg`
    ${({ theme }) => `
        height: ${theme.dimensions.button.icon};
        width: ${theme.dimensions.button.icon};
    `}
`;
