import styled from 'styled-components';

export const Section = styled.section`
    ${({ background, theme }) =>
        background
            ? `
        background-color: ${theme.colors[background] || background};
    `
            : ''}
    justify-content: center;
    position: relative;
    display: flex;
`;
