const COLOR_RANGE = 255;
const AMOUNT = 0.03;

/**
 * Darkens given color
 * @param {string} color - HEX color
 * @param {number} [amount=1] - How much darker?
 * @returns {string} Darkened color
 */
export const darken = (color, amount = 1) => {
    const val = Math.floor(COLOR_RANGE * AMOUNT * amount);
    return color.replace(/\w{2}/g, (c) => {
        const _c = Math.max(parseInt(c, 16) - val, 0);
        return _c.toString(16).padStart(2, 0);
    });
};
