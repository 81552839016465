import styled from 'styled-components';
import theme from '../../../theme/theme';

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 570px) {
        flex-wrap: wrap;
    }
`;

const IconBox = styled.div`
    ${({ width }) => (width ? `width: ${width};` : '')}
    margin-bottom: 1rem
`;

const PhoneCard = styled.div`
    @media (max-width: 1024px) {
        flex-basis: calc(50% - 40px);
        height: 390px;
        max-width: 204px;
        width: 204px;
    }
    ${({ backgroundColor }) =>
        backgroundColor ? `background-color: ${backgroundColor};` : ''}
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    min-width: 204px;
    max-height: 390px;
    box-sizing: border-box;
    flex: 1 0 calc(25% - 40px);
    border-radius: 25px;
    padding: 1rem;
    margin: 1rem;
`;
const PhoneBox = styled.div`
    background-color: ${theme.colors.background};
    @media (max-width: 1024px) {
        flex-basis: calc(50% - 40px);
    }
    border: 5px solid #080808;
    max-height: 330px;
    box-sizing: border-box;
    flex: 1 0 calc(25% - 40px);
    border-radius: 15px;
    padding: 5px;
    margin-bottom: 1rem;
`;

const TextBox = styled.div`
    @media (max-width: 570px) {
        margin-right: 0px;
        margin-bottom: 1rem;
    }
    h1,
    h3,
    p,
    li {
        color: ${theme.colors.primary};
    }
    h1,
    h3,
    p,
    a {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    ul {
        padding-left: 25px;
        margin: 0px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
    margin-bottom: 5rem;
`;

const NavBox = styled.div`
    @media (max-width: 800px) {
        padding: 0px;
        margin-bottom: 5rem;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-left: 15rem;
    padding-right: 15rem;
`;

export {
    TextBox,
    PhoneBox,
    IconBox,
    Content,
    OuterContainer,
    NavBox,
    PhoneCard,
};
