import React from 'react';
import { SectionContainer } from './SectionContainer';
import { SectionTextContent } from './SectionTextContent';

export const SectionTemplate = ({
    headingText,
    headingColor = 'primary',
    headingProps,
    subHeadingText,
    subHeadingColor = 'primary',
    subHeadingProps,
    paragraphText,
    paragraphProps,
    header,
    footer,
    reverse,
    imageContent,
    textComponent,
    margin,
    mediaMixin,
    mediaFlexDirection,
    backgroundColor,
    id,
    children,
}) => {
    const textContentTemplate = (
        <>
            <SectionTextContent
                headingText={headingText}
                subHeadingText={subHeadingText}
                headingColor={headingColor}
                paragraphText={paragraphText}
                headingProps={headingProps}
                subHeadingProps={subHeadingProps}
                subHeadingColor={subHeadingColor}
                paragraphProps={paragraphProps}
            />
            {textComponent}
        </>
    );

    return (
        <SectionContainer
            header={header}
            footer={footer}
            textContent={textContentTemplate}
            imageContent={imageContent}
            reverse={reverse}
            margin={margin}
            subHeadingColor={subHeadingColor}
            mediaMixin={mediaMixin}
            mediaFlexDirection={mediaFlexDirection}
            backgroundColor={backgroundColor}
            id={id}
        >
            {children}
        </SectionContainer>
    );
};
