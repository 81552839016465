import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { transition } from '../../../utils/mixins';

export const FooterItem = ({ external, ...props }) => {
    return (
        <li>
            <StyledLink as={external ? 'a' : Link} {...props} />
        </li>
    );
};
const StyledLink = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    ${transition(['color'])}
    ${({ theme }) => `
        margin-bottom: ${theme.spacing.element};
        color: ${theme.colors.text};
        &:hover {
            color: ${theme.colors.primary};
        }
    `}
`;
