import React from 'react';
import FavouriteRestaurants from './favouriteRestaurants.jsx';
import RobotDelivery from './robotDelivery.jsx';
import InteractiveMap from './interactiveMap.jsx';
import RewardsPrograms from './rewardsPrograms.jsx';
import { OuterContainer } from './Containers.js';

const FeatureSubPages = () => {
    return (
        <OuterContainer>
            <FavouriteRestaurants />
            <RobotDelivery />
            <InteractiveMap />
            <RewardsPrograms />
        </OuterContainer>
    );
};

export default FeatureSubPages;
