import React from 'react';
import {
    FaTwitter,
    FaFacebookSquare,
    FaLinkedin,
    FaGithub,
} from 'react-icons/fa';
import styled from 'styled-components';
import { Section, SectionContent } from '../../Section';
import { FooterItem as Item } from './FooterItem';
import { Stack } from '../../Stack';
import { Logo } from '../Logo';
import { FooterList } from './FooterList';
import { media } from '../../../utils/mixins';
import sitemap from '../Navigation/sitemap';
import { Paragraph } from '@cheapreats/react-ui';

export const Footer = () => {
    return (
        <Section as="footer">
            <Content>
                <Items direction="row" spacing="page" wrap="wrap">
                    <LogoColumn direction="column" spacing="element">
                        <Logo />
                        <Paragraph size="default">
                            Order now, pickup later.
                        </Paragraph>
                    </LogoColumn>
                    <FooterList label="Pages">
                        <Item to="/">Home</Item>
                        {sitemap[0].items.map((item, index) => (
                            <Item to={item.to} key={index}>
                                {item.label}
                            </Item>
                        ))}
                    </FooterList>
                    <FooterList label="Services">
                        {/* Automatically Fetches any Links from the DropDown */}
                        {sitemap[1].items.map(
                            (item, index) =>
                                item.href && (
                                    <Item
                                        href={item.href}
                                        target="_blank"
                                        key={index}
                                        external
                                    >
                                        {item.label}
                                    </Item>
                                ),
                        )}
                    </FooterList>
                    <FooterList label="Social Media">
                        <Item
                            href="https://twitter.com/cheapreats"
                            target="_blank"
                            external
                        >
                            <Icon as={FaTwitter} /> Twitter
                        </Item>
                        <Item
                            href="https://www.facebook.com/cheapreats"
                            target="_blank"
                            external
                        >
                            <Icon as={FaFacebookSquare} /> Facebook
                        </Item>
                        <Item
                            href="https://www.linkedin.com/company/cheapreats"
                            target="_blank"
                            external
                        >
                            <Icon as={FaLinkedin} /> LinkedIn
                        </Item>
                        <Item
                            href="https://github.com/cheapreats"
                            target="_blank"
                            external
                        >
                            <Icon as={FaGithub} /> Github
                        </Item>
                    </FooterList>
                </Items>
                <Legal size="small">
                    © CheaprEats 2020. All Rights Reserved. |{' '}
                    <a
                        href="https://stripe.com/en-ca/privacy"
                        rel="noreferrer"
                        target="_blank"
                    >
                        Privacy Policy
                    </a>
                    {' | '}
                    <a
                        href="https://stripe.com/en-ca/EULA"
                        rel="noreferrer"
                        target="_blank"
                    >
                        EULA
                    </a>
                </Legal>
            </Content>
        </Section>
    );
};

const Content = styled(SectionContent)`
    ${({ theme }) => `
        padding-bottom: ${theme.spacing.container};
        padding-top: ${theme.spacing.container};
    `}
`;

const Items = styled(Stack)`
    ${media.phone`
        flex-direction: column;
    `}
`;

const LogoColumn = styled(Stack)`
    margin-right: auto;
    ${media.tablet`
        width: 100%;
        margin-bottom: ${({ theme }) => theme.spacing.container} !important;
    `}
`;

const Icon = styled.svg`
    margin-right: 0.6rem;
`;

const Legal = styled(Paragraph)`
    text-align: center;
    ${({ theme }) => `
        margin-top: ${theme.spacing.container};
    `}
`;
