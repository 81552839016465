const spacing = {
    page: '6rem', // Spacing for page sections
    section: '3rem', // Spacing between containers
    container: '1.75rem', // Padding of containers
    component: '0.75rem', // Spacing between components
    element: '0.25rem', // Spacing between elements
    input: '0.7rem 1.2rem', // Padding of inputs
};

const theme = {
    fonts: {
        heading: `'Quicksand', sans-serif`,
        text: `'Lato', sans-serif`,
    },
    colors: {
        primary: '#EE2434',
        background: '#ffffff',
        secondaryBackground: '#f5f5f5',
        heading: '#000000',
        text: '#8c8c8c',
        black: '#000000',
    },
    shadow: `0 0 1rem rgba(0,0,0,0.1)`,
    dimensions: {
        radius: '0.6rem',
        border: '1.5px',
        nav: {
            menuWidth: '12rem',
            spacing: '0.5rem',
        },
        page: {
            maxWidth: '75rem',
        },
        button: {
            spacing: '0.55rem',
            icon: '0.8rem',
        },
        text: {
            splash: '3.5rem',
            heading: '2.5rem',
            large: '1.5rem',
            normal: '1.2rem',
            smaller: '1rem',
            button: '0.87rem',
            small: '0.85rem',
        },
    },
    media: {
        tablet: 800,
        stayInTouchWrap: 738,
        phone: 460,
    },
    speed: {
        fast: '150ms',
        normal: '250ms',
        slow: '400ms',
        page: '600ms',
    },
    spacing,
};

module.exports = theme;
