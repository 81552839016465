import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Stack } from '../Stack';
import { Heading as H } from '@cheapreats/react-ui';
export const Logo = () => {
    const data = useStaticQuery(query);
    return (
        <Stack spacing="element">
            <Image fluid={data.file.childImageSharp.fluid} />
            <Heading type="h6" color="primary">
                CheaprEats
            </Heading>
        </Stack>
    );
};

const query = graphql`
    {
        file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
                fluid(maxWidth: 120) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const Image = styled(Img)`
    width: 2rem;
`;

const Heading = styled(H)`
    ${({ theme }) => `
        font-family: ${theme.fonts.heading};
        margin-top: ${theme.spacing.element};
    `}
`;
