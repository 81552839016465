import React from 'react';
import styled from 'styled-components';
import { Stack } from '../Stack';
import { Section } from './Section';
import { SectionContent } from './SectionContent';
// import { graphql } from 'gatsby';
import { media } from '../../utils/mixins';
import { Text } from '../Text';
import Img from 'gatsby-image';
import mask from '../../images/blob-shape.svg';

export const ProductFeatureSection = ({
    title,
    subTitle,
    body,
    image,
    ...props
}) => (
    <Section as="section" {...props}>
        <Container
            direction="row"
            spacing="auto"
            justify="center"
            align="center"
        >
            <Content direction="column">
                <Heading size="large" as="h1" color="primary">
                    <span>{title}</span>
                    <span>{subTitle}</span>
                </Heading>
                <Body size="small">{body}</Body>
            </Content>
            <Image fluid={image} />
        </Container>
    </Section>
);

// const query = graphql`
//     {
//         dinning: file(relativePath: { eq: "dinning.jpg" }) {
//             childImageSharp {
//                 fluid(maxWidth: 800, quality: 90) {
//                     ...GatsbyImageSharpFluid
//                 }
//             }
//         }
//     }
// `;

const Container = styled(SectionContent)`
    ${media.tablet`
        flex-direction: column-reverse;
    `}
`;

const Content = styled(Stack)`
    padding-right: ${({ theme }) => theme.spacing.container};
    ${media.tablet`
        text-align: center;
        padding-right: 0;
        margin-left: auto;
    `}
`;

const Heading = styled(Text)`
    flex-direction: column;
    display: flex;
    ${media.tablet`
        margin-top: ${({ theme }) => theme.spacing.container};
    `}
    ${({ theme, color }) =>
        color ? `color: ${theme.colors[color] || color};` : ''}
`;

const Body = styled(Text)`
    ${({ align }) => `
align-items: ${align};
`}
`;

const Image = styled(Img)`
    mask: url(${mask});
    mask-size: cover;
    max-width: 26rem;
    max-height: 26rem;
    width: 100%;
    height: auto;
    ${media.tablet`
        max-width: 20rem;
        max-height: 20rem;
    `}
`;
