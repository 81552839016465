export default [
    {
        label: 'Company',
        items: [
            {
                label: 'About Us',
                to: '/about',
            },
            {
                label: 'Features',
                to: '/features',
            },
            {
                label: 'Ethics',
                to: '/ethics',
            },
            {
                label: 'Loyalty Program',
                to: '/loyaltyProgram',
            },
            {
                label: 'Students Ambassador',
                to: '/studentsAmbassador',
            },
            {
                label: 'Client',
                to: '/client',
            },
            {
                label: 'Press Kit',
                to: '/press',
            },
            {
                label: 'Support',
                to: '/support',
            },
            {
                label: 'Sitemap',
                to: '/sitemap',
            },
            {
                label: 'Mentorship',
                to: '/mentorship',
            },
        ],
    },
    {
        label: 'Vendors',
        items: [
            {
                label: 'Why CheaprEats',
                to: '/vendor',
            },
            {
                label: 'Store Dashboard',
                href: 'https://dashboard.cheapreats.com',
            },
            {
                label: 'POS Terminal',
                to: '/pointOfSale',
            },
        ],
    },
    {
        label: 'Developers',
        items: [
            {
                label: 'CheaprEats SDK',
                href: 'https://js-sdk.cheapreats.com',
            },
            {
                label: 'CheaprEats UI Kit',
                href: 'https://cheapreats-ui.netlify.app',
            },
        ],
    },
    {
        label: 'Customers',
        items: [
            {
                label: 'Buy a Gift Card',
                to: '/giftcard',
            },
            {
                label: 'Promotions',
                to: '/stack',
            },
        ],
    },
];
