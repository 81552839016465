import React from 'react';
import Img from 'gatsby-image';
import {
    TextBox,
    PhoneBox,
    IconBox,
    Content,
    NavBox,
    OuterContainer,
    PhoneCard,
} from './Containers.js';
import { usePhoneImages } from '../../queries/usePhoneImages.js';
import { useFeatureIcons } from '../../queries/useFeatureIcons.js';
import {
    FAVOURITE_RESTAURANTS,
    STAR,
    ROBOT,
    LOCATION,
    GIFTBOX,
} from './Constants';

const FavouriteRestaurants = () => {
    const phoneQuery = usePhoneImages().images.nodes;
    const iconQuery = useFeatureIcons().images.nodes;
    return (
        <OuterContainer>
            <NavBox>
                <IconBox width="35px">
                    <Img fluid={iconQuery[STAR].childImageSharp.fluid} />
                </IconBox>
                <IconBox width="35px">
                    <Img
                        fluid={iconQuery[ROBOT].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
                <IconBox width="27px">
                    <Img
                        fluid={iconQuery[LOCATION].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
                <IconBox width="35px">
                    <Img
                        fluid={iconQuery[GIFTBOX].childImageSharp.fluid}
                        style={{ opacity: '0.5' }}
                    />
                </IconBox>
            </NavBox>
            <Content>
                <TextBox>
                    <IconBox width="35px">
                        <Img fluid={iconQuery[STAR].childImageSharp.fluid} />
                    </IconBox>

                    <h1>Favourite Restaurants</h1>
                    <h3>One click away:</h3>
                    <p>
                        Now it's even easier to order your go-to dish from your
                        favourite places. When stores are favourited, they will
                        conveniently be placed at the top of the "Stores" tab.
                    </p>
                    <h3>Quick and easy</h3>
                    <ul>
                        <li>Head over to the CheaprEats app.</li>
                        <li>Select the restaurant.</li>
                        <li>Click the star at the right corner.</li>
                        <li>Ta-da that store is now favourited.</li>
                    </ul>
                </TextBox>
                <PhoneCard backgroundColor="#295FA6">
                    <PhoneBox padding="16px">
                        <Img
                            fluid={
                                phoneQuery[FAVOURITE_RESTAURANTS]
                                    .childImageSharp.fluid
                            }
                        />
                    </PhoneBox>
                </PhoneCard>
            </Content>
        </OuterContainer>
    );
};

export default FavouriteRestaurants;
