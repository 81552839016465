import React from 'react';
import styled from 'styled-components';
import { Stack } from '../Stack';

export const SectionContent = ({ direction = 'column', ...props }) => (
    <Content direction={direction} {...props} />
);

const Content = styled(Stack)`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    ${({ theme, color }) =>
        color ? `background-color: ${theme.colors[color] || color};` : ''}
    ${({ theme, padding = 'section' }) => `
        max-width: ${theme.dimensions.page.maxWidth};
        padding: ${theme.spacing[padding] || padding};
    `}
`;
