import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Mixins } from '@cheapreats/react-ui';

export const PhoneContainer = ({
    tilt,
    backgroundImage,
    backgroundColor,
    padding,
    width = '300px',
    height = '500px',
    mediaMixin = 'tablet',
    mediaWidth = '200px',
    mediaHeight = '400px',
    border = true,
    image,
    header,
    children,
}) => {
    return (
        <PhoneBox
            tilt={tilt}
            padding={padding}
            picture={backgroundImage}
            backgroundColor={backgroundColor}
            width={width}
            mediaMixin={mediaMixin}
            mediaWidth={mediaWidth}
            height={height}
            mediaHeight={mediaHeight}
            border={border}
        >
            {header}
            <PhoneImageContainer
                height={height}
                width={width}
                mediaMixin={mediaMixin}
                mediaHeight={mediaHeight}
            >
                <Img style={{ width: '100%', height: '100%' }} fluid={image} />
            </PhoneImageContainer>
            <ChildContainer>{children}</ChildContainer>
        </PhoneBox>
    );
};

export const PhoneBox = styled.div`
    ${({ picture, padding, backgroundColor, width, tilt, height, border }) => `
        ${picture ? `background: url(${picture});` : ''}
        ${backgroundColor ? `background-color: ${backgroundColor};` : ''}
        ${padding ? `padding: ${padding};` : ''}
        ${width ? `width: ${width};` : ''}
        ${
            tilt
                ? `
            transform: rotate3d(
                1,
                1,
                1,
                20deg
            ); `
                : `transform: rotate3d(
                1,
                1,
                1,
            ); `
        }
        ${height ? `height: ${height};` : ''}
        ${
            border
                ? `
        &::before {
            position: absolute;
            left: calc(50% - 28%);
            top: 0;
            width: 56%;
            background: #080808;
            height: 20px;
            content: '';
            border-radius: 0 0 15px 15px;
        };
        border: 6px solid #080808;
        `
                : ''
        }
    `}
    ${({ mediaMixin, mediaWidth, mediaHeight }) =>
        Mixins.media(
            mediaMixin,
            `width: ${mediaWidth}; height: ${mediaHeight}; margin: 10px`,
        )}
    ${Mixins.flex('column')}
    margin: 5px;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    position: relative;
    transform: rotate3d(1, 1, 1);
`;
const PhoneImageContainer = styled.div`
    border: 2px solid black;
    margin-top: 10px;
    border-radius: 10px;
    padding: 2px;
    display: flex;
    overflow: hidden;
    ${({ height, theme }) => `
        height: calc(${height} - 20%);
        background-color: ${theme.colors.background};
    `}
    ${({ mediaMixin, mediaHeight }) =>
        Mixins.media(mediaMixin, `height: calc(${mediaHeight} - 20%);`)}
`;
const ChildContainer = styled.div`
    margin-top: 15px;
    height: 20%;
`;
