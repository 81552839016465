const React = require('react');
const { Global } = require('@cheapreats/react-ui');
const GlobalStyles = require('./GlobalStyles');
const merge = require('./mergeThemes');

module.exports = ({ children }) => (
    <Global extend={merge} style={GlobalStyles}>
        {children}
    </Global>
);
