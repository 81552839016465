const EASE = 'cubic-bezier(0.4, 0.0, 0.2, 1)';

/**
 * Defined transition for specific property
 * @typedef {Object} TransitionOption
 * @property {string} property - The transition property that is being targetted
 * @property {string|number} duration - The duration (default in milliseconds)
 */

/**
 * Adds transitions in batch
 * @param {string[]|TransitionOption[]} items - The items to transition with optional configuration
 * @param {string|number} duration - The duration of transition
 * @returns {string} CSS style for transition for provided items
 */
export const transition = (items, duration = 'normal') => ({ theme }) => `
    transition: ${items
        .map((item) => {
            const d = item.duration || duration;
            const p = item.prop || item;
            return `${p} ${theme.speed[d] || d} ${EASE}`;
        })
        .join(', ')};
`;
